import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Img from 'gatsby-image';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    hydrogen: file(relativePath: { eq: "hydrogen/hydrogenstation.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const HydrogenPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO
        title={
          'Hydrogen. We are specialists at hydrogen. Read about which services we can provide you with in this field.'
        }
      />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-48 mb-48">
          <div>
            <h1 className={styles.welcomeText}>Hydrogen</h1>
            <p className={styles.descriptionText}>
              Hydrogen is very different from conventional fuels, and safe handling of hydrogen
              requires proper understanding and often alternative mitigation strategies. In our
              experience, it is wise to involve expertise early, this may limit the need for major
              and costly changes late in a project. At early stage, dedicated spreadsheet
              consequence models can give immediate input on risk/hazards, while at a later stage,
              some of the assessments will be performed using CFD for more accurate predictions.
              Explosion risk is a main focus when handling hydrogen. Our assessments provide
              detailed insight in consequences of phenomena like LH2-vapour dispersion, high
              pressure tank burst, and DDT/detonation, all highly relevant for hydrogen but
              challenging to model.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.hydrogen.childImageSharp.fluid} alt="Hydrogen fuel" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.psep.2023.04.021"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD-modelling of large-scale LH2 release and explosion experiments."
              >
                Hansen, O. R., Hansen, E. S. (2023). CFD-modelling of large-scale LH2 release and explosion experiments.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.3303/CET2290016"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD study to assess safety aspects of TPRD releases from heavy-duty hydrogen vehicles and trains in tunnels"
              >
                Hansen, O. R., Hansen, E. S., Kjellander, M. T., Martini, R. (2022). CFD study to assess safety aspects of TPRD releases from heavy-duty hydrogen vehicles and trains in tunnels.
              </a>
            </div>
          <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2019.09.060"
                target="_blank"
                rel="noopener noreferrer"
                title="Liquid hydrogen releases show dense gas behavior."
              >
                Hansen, O. R. (2020). Liquid hydrogen releases show dense gas behavior.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2019.05.128"
                target="_blank"
                rel="noopener noreferrer"
                title="Concept risk
                assessment of a hydrogen driven high speed passenger ferry."
              >
                Aarskog, F. G., Hansen, O. R., Strømgren, T., Ulleberg, Ø. (2020). Concept risk
                assessment of a hydrogen driven high speed passenger ferry.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.3303/CET1977004"
                target="_blank"
                rel="noopener noreferrer"
                title="Hydrogen infrastructure - efficient risk assessment and design
                optimization approach."
              >
                Hansen, O. R. (2019). Hydrogen infrastructure - efficient risk assessment and design
                optimization approach.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2010.04.132"
                target="_blank"
                rel="noopener noreferrer"
                title="Can the addition of hydrogen to natural gas reduce the explosion risk?"
              >
                Middha, P, Engel, D., Hansen, O.R. (2011). Can the addition of hydrogen to natural
                gas reduce the explosion risk?
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jhazmat.2010.02.061"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD
                calculations of gas leak dispersion and subsequent gas explosions: Validation
                against ignited impinging hydrogen jet experiments."
              >
                Middha, P, Engel, D., Hansen, O.R., Grune, J., Kotchourko, A. (2011). CFD
                calculations of gas leak dispersion and subsequent gas explosions: Validation
                against ignited impinging hydrogen jet experiments.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/268426907_AN_INTERCOMPARISON_EXERCISE_ON_THE_CAPABILITIES_OF_CFD_MODELS_TO_PREDICT_DEFLAGRATION_OF_A_LARGE-SCALE_H_2_-AIR_MIXTURE_IN_OPEN_ATMOSPHERE"
                target="_blank"
                rel="noopener noreferrer"
                title="An intercomparison exercise on the capabilities of CFD models
                  to reproduce a large-scale hydrogen deflagration in open atmosphere."
              >
                García, J., Baraldi, D., Gallegoa, E., Beccantini, A., Crespoa, A., Hansen, O. R.,
                Høiset, S., Kotchourko, A., Makarov, D., Migoya, E., Molkov, V., Voort, M. M.,
                Yanez, J.. (2010). An intercomparison exercise on the capabilities of CFD models to
                reproduce a large-scale hydrogen deflagration in open atmosphere
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2009.07.020"
                target="_blank"
                rel="noopener noreferrer"
                title="Validation of CFD-model for hydrogen dispersion."
              >
                Middha, P, Hansen, O.R., Storvik, I. E. (2009). Validation of CFD-model for hydrogen
                dispersion.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2009.01.055"
                target="_blank"
                rel="noopener noreferrer"
                title="An inter-comparison exercise on the capabilities of CFD models
                to predict the short and long term distribution and mixing of hydrogen in a garage."
              >
                Venetsanos, A. G., Papanikolaou, E., Delichatsios, M., Garcia, J., Hansen, O. R.,
                Heitsch, M., Huser, A., Jahn, W., Jordan, T., Lacome, J.-M., Ledin, H. S., Makarov,
                D., Middha, P., Studer, E., Tchouvelev, A. V., Teodorczyk, A., Verbecke, F., Van der
                Voort, M. M. (2009). An inter-comparison exercise on the capabilities of CFD models
                to predict the short and long term distribution and mixing of hydrogen in a garage.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2009.02.004"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD simulation study to investigate the risk from
                hydrogen vehicles in tunnels."
              >
                Middha, P, Hansen, O.R. (2009). CFD simulation study to investigate the risk from
                hydrogen vehicles in tunnels.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="http://publica.fraunhofer.de/documents/N-118665.html"
                target="_blank"
                rel="noopener noreferrer"
                title="Deflagration to detonation transition
                (DDT) in jet ignited hydrogen-air mixtures: large scale experiments and FLACS CFD
                predictions"
              >
                Middha, P, Hansen, O.R., Schneider, H. (2007). Deflagration to detonation transition
                (DDT) in jet ignited hydrogen-air mixtures: large scale experiments and FLACS CFD
                predictions.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}></div>
        </div>
      </Layout>
    </>
  );
};

export default HydrogenPage;
